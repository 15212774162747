import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FunctionComponent } from 'react';
import ThemeProvider from './ThemeProvider';

const ComponentsProvider: FunctionComponent<{
    children: JSX.Element;
}> = ({ children }) => (
    <ThemeProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {children}
        </MuiPickersUtilsProvider>
    </ThemeProvider>
);

export default ComponentsProvider;
