import ScheduleIcon from '@material-ui/icons/Schedule';
import { KeyboardTimePicker } from '@material-ui/pickers';
import formatISO from 'date-fns/formatISO';
import React, { FunctionComponent } from 'react';

export interface TimeFieldProps {
    label: string;
    value: Date|null;
    onChange: (newValue: Date|null) => void;
    className?: string;
    required?: boolean;
    helperText?: string;
    ampm?: boolean;
    format: string;
    utc?: boolean;
    error?: boolean;
}

const TimeField: FunctionComponent<TimeFieldProps> = ({
    label, value, onChange, className, required,
    helperText, ampm, format, utc, error,
}) => (
    <KeyboardTimePicker
        value={value}
        onChange={newValue => {
            const result = (() => {
                if (newValue === null) {
                    return null;
                }

                return utc
                    ? new Date(formatISO(newValue))
                    : newValue;
            })();

            onChange(result);
        }}
        label={utc ? `${label} (UTC)` : label}
        className={className}
        required={required}
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, @typescript-eslint/no-unnecessary-condition
        helperText={helperText || ' '}
        inputVariant="filled"
        ampm={ampm ?? false}
        autoOk
        format={format}
        variant="inline"
        keyboardIcon={<ScheduleIcon fontSize="small"/>}
        fullWidth
        error={error}
    />
);

export default TimeField;
