import { Grid, Link, Typography } from '@material-ui/core';
import format from 'date-fns/format';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import typographyStyles from '../../styles/useTypographyStyles';

const Footer: FunctionComponent = () => {
    const currentYear = format(new Date(), 'y');
    const { subtitle1Override, inlineLink, grey } = typographyStyles();
    const { t } = useTranslation();

    return (
        <Grid
            container
            item
            xs={12}
            justify="center"
            alignItems="center"
            style={{
                height: '80px',
                backgroundColor: '#EEF3F9',
            }}
        >
            <Typography
                variant="subtitle1"
                align="center"
                className={subtitle1Override}
            >
                {t('copyright.companyName')}
                {' © '}
                {
                    t(
                        'copyright.yearFormat',
                        {
                            startYear: config.copyrightStartYear,
                            currentYear,
                        },
                    )
                }
            </Typography>

            <Link
                variant="subtitle1"
                href={`${config.marketingWebsiteUrl}/privacy`}
                className={`${inlineLink} ${grey}`}
                target="_blank"
            >
                <strong>
                    {t('signedOutLayout.privacyPolicy')}
                </strong>
            </Link>

            <Link
                variant="subtitle1"
                href={`${config.marketingWebsiteUrl}/terms-and-conditions`}
                className={`${inlineLink} ${grey}`}
                target="_blank"
            >
                <strong>
                    {t('signedOutLayout.termsAndConditions')}
                </strong>
            </Link>
        </Grid>
    );
};

export default Footer;
